import React, { Component, useEffect, useState } from 'react';
import { Row, Col, Container, Button, ModalHeader, ModalFooter, Modal, ModalBody } from "reactstrap";
import html2canvas from 'html2canvas';

import { Link, useParams, Router,useNavigate, useSearchParams } from 'react-router-dom';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import InputColor from 'react-input-color';
import Plots from 'react-plotly.js';
import { csv } from 'd3';
import ChartHeader from '../../component/ChartHeader';
import Chart from './Chart';
import { API_URL } from '../../../config';
import ChartSci from './ChartSci';

const GroupChartTable = () => {

    const [groupaction, setGroupaction] = useState()
    const [searchParams] = useSearchParams()
    const showsci = searchParams.get('showsci')

    const handleDataFromChild = (data) => {
        setGroupaction(data);
    };

    const accessToken = localStorage.getItem('accessToken');
    const { config, session, showclock, record, currentConfig } = useParams();
    const navigate = useNavigate();
    const [graphs, setgraphs] = useState([]);
    const [notes, setNotes] = useState(null);
    const [reportName, setReportName] = useState(null);
    const [showHeader, setShowHeader] = useState(false);
    const [sessionDate, setSessionDate] = useState(null);
    const userId = localStorage.getItem('user_id');
    const [showActualTime, setShowActualTime] = useState(true);
    const selectedClient = localStorage.getItem('selectedClient');
    const [linkGraphs, setLinkGraphs] = useState(true);
    const [reporterror, setReporterror] = useState(false)
    const [changesMade, setChangesMade] = useState(false)

    // const [value, setValue] = useState(0);
    // const [point, setPoint] = useState(25);
    const [groupProfile, setGroupProfile] = useState([]);
    const [signalConfig, setSignalConfig] = useState({})
    const [signalStat, setSignalStat] = useState({})
    const [showSignalStat, setShowSignalStat] = useState(false)

    const [requestProcessingModal, setrequestProcessingModal] = useState(false);
    const requestProcessingModalToggle = () => setrequestProcessingModal(!requestProcessingModal);

    const [savingReportConfirmation, setSavingReportConfirmation] = useState(false);
    const savingReportConfirmationToggle = () => setSavingReportConfirmation(!savingReportConfirmation);

    const [savingAlternateConfirmation, setSavingAlternateConfirmation] = useState(false);
    const savingAlternateConfirmationToggle = () => setSavingAlternateConfirmation(!savingAlternateConfirmation);


    const [requestProcessedModal, setrequestProcesedModal] = useState(false);
    const requestProcessedModalToggle = () => setrequestProcesedModal(!requestProcessedModal);

    const [zoomEnabled, setZoomEnabled] = useState(true);

    const [dragMode, setDragMode] = useState('pan');

    const [globalConfig, setGlobalConfig] = useState({
        color: '',
        signal: 1,
        type: '',
        avg: 30,
        xmin: '',
        thick: 0.5,
        xextreme: '',
        xmax: '',
        ymin: 0,
        ymax: 50,
        record: '',
        graph_order: '',
        comment: '',
        row: '',
        clientSerial: '',
        col: '',
        xrange: 0,
        units: 'mmhg',
        annotation: 1,
        grid: 2,
        showGrid: false,
        invert: 2,
        position: '',
        lineType: '',
        disabledType: '',
        signalType: '',
        thresholdtLine: false,
        thresholdtLineType: 'dot',
        thresholdtcolor: '',
        thresholdthick: '',
        thresholdvalue: '',

    });

    const setConfig = (_signal, data) => {
        let _temp = signalConfig;
        _temp[_signal] = {
            color: data.color,
            signal: data.signal,
            type: data.type,
            avg: data.avg,
            xmin: data.xmin / 1e3,
            thick: data.thick,
            xextreme: data.xextreme / 1e3,
            xmax: data.xmax / 1e3,
            ymin: data.ymin,
            ymax: data.ymax,
            record: data.record,
            graph_order: data.graph_order,
            comment: data.comment,
            row: data.row,
            clientSerial: data.clientSerial,
            col: data.col,
            xrange: data.xrange,
            units: data.units,
            annotation: data.annotation,
            grid: data.grid,
            inverty: data.inverty,
            stat: data.stat,
            yposition: data.yposition,
            lineType: data.lineType
        }
        // console.log("signal config",_temp);
        setSignalConfig(_temp)
    }


    const setStats = (_signal, data) => {
        // alert("her")
        // // console.log("signal data",data)
        let _temp = signalStat;
        let _tempData = [];
        data.map((v, i) => {
            _tempData.push({
                x: v.x,
                mean: v.mean,
                median: v.median,
                sd: v.sd,
            })
        })
        _temp[_signal] = _tempData;
        // // console.log("signal data 1",_temp);
        setSignalStat(_temp)
        setTimeout(() => {
            setShowHeader(true);
        }, 1000 * graphs.length);
    }

    useEffect(() => {
        reportChart();
        getGroupProfile();

    }, []);

    const reportChart = () => {
        fetch(API_URL + "/report/config?report_id=" + currentConfig,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.warn("result", resp);
                    setgraphs(resp.graphs)


                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }



        })
    }
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }


    const exportExcel = () => {
        setrequestProcesedModal(true);

    }

    const getGroupProfile = () => {
        // alert("here")
        fetch(API_URL + "/group/profile/" + selectedClient, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
        }).then((result) => {
            result.json().then((resp) => {
                setGroupProfile(resp.groupProfile);

            })
        })

    }

    const Handlereportsavegroupinput = (e) => {

        setReportName(e.target.value)

        let inputVal = e.target.value;

        if (inputVal == 0) {
            setReporterror(true)
        } else[
            setReporterror(false)
        ]

    }


    const performAction = () => {
        if (groupaction == "reportConfig") {
            reportconfigupdate();
        }
        else if (groupaction == "altConfig") {
            reportconfigalternateupdate();
        }
        else if (groupaction == "record") {
            reportrecordupdate();
        }
        else if (groupaction == "dashboard") {
            navigate("/dashboard")
        }

    }

    function detectMob() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const saveReport = async () => {

        setrequestProcessingModal(true)
        let dataDevice = detectMob();

        let devicesorce;

        if (dataDevice == true) {

            devicesorce = 2

        } else {
            devicesorce = 1

        }

        if (reportName?.length == 0 || reportName == undefined || reportName == "" || reportName == null) {
            setrequestProcessingModal(false)
            setReporterror(true)
            return false
        } else {
            try {
                const canvas = await html2canvas(document.getElementById("chart-table"));
                const session_id = session;
                const type = 0;
                const status = 1;
                const clock = showclock;
                const dataimg = canvas.toDataURL('image/png');

                const formData = {
                    'data': dataimg,
                    'type': type,
                    'status': status,
                    'session_id': session_id,
                    'clock': clock,
                    '_device': devicesorce,
                };

                const response1 = await fetch(API_URL + "/save/screenshot", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken,
                    },
                    body: JSON.stringify(formData),
                });
                const result1 = await response1.json();

                const timezone = new Date().getTimezoneOffset();
                const formDataReport = {
                    'pid': currentConfig,
                    'session_id': session,
                    'name': reportName,
                    'notes': notes,
                    'status': 1,
                    'clock': clock,
                    'timezone': timezone,
                    '_device': devicesorce,
                };

                const response2 = await fetch(API_URL + "/save/single/report", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken,
                    },
                    body: JSON.stringify(formDataReport),
                });
                const result2 = await response2.json();
                const reportId = result2.reports.insertId;

                await Promise.all(groupProfile.map(async (group) => {
                    const _serial = group.name;
                    const saveGroupReportGraphPromises = graphs.map(async (graph, i) => {
                        const _config = signalConfig[_serial];
                        
                        const response3 = await fetch(API_URL + "/save/group/report/graph", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'x-access-token': accessToken,
                            },
                            body: JSON.stringify({
                                reportId,
                                _config,
                                serial: _serial
                            }),
                        });
                        const result3 = await response3.json();

                        if (i === (graphs.length - 1)) {
                            setrequestProcessingModal(false);
                            setrequestProcesedModal(true);
                            setSavingReportConfirmation(false);

                            setTimeout(() => {
                                performAction();
                            }, 3000);
                        }
                    });

                    await Promise.all(saveGroupReportGraphPromises);
                }));
            } catch (error) {
                // Handle any errors that occur during the async operations
                console.error(error);
            }
        }

    };

    const saveReportConfig = () => {
        setSavingAlternateConfirmation(false)
        runSaveReportConfig();

    }

    const runSaveReportConfig = () => {
        setrequestProcessingModal(true)
        let formData = {
            'original': config,
            'user': userId,
            'name': "Alternate",
            'type': "1",
            'status': "1"
        };

        fetch(API_URL + "/save/single/alertnate/report/config", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(formData),
        }).then(async (result) => {
            let _res = await result.json();
            let alternateId = _res.reports.insertId;

            graphs.map((v, i) => {
                let _config = signalConfig[v.signal_name];
                // // console.log(_config) ;
                fetch(API_URL + "/save/single/alertnate/report/graph", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken,
                    },
                    body: JSON.stringify({
                        alternateId,
                        _config,
                        signal_name: v.signal_name
                    }),
                }).then(async (result) => {
                    let _res = await result.json();


                    if (i == (graphs.length - 1)) {
                        setrequestProcessingModal(false);
                        setrequestProcesedModal(true)
                    }


                })
            })


        })

    }


    return (
        <div>
            {
                graphs.length > 0 &&
                <ChartHeader changesMade={changesMade} setChangesMade={setChangesMade} sendData={handleDataFromChild} group={true} setLinkGraphs={setLinkGraphs} linkGraphs={linkGraphs} setShowActualTime={setShowActualTime} showActualTime={showActualTime} setShowSignalStat={setShowSignalStat} showSignalStat={showSignalStat} setSessionDate={setSessionDate} setSavingReportConfirmation={setSavingReportConfirmation} setrequestProcessingModal={setrequestProcessingModal} setrequestProcesedModal={setrequestProcesedModal} setNotes={setNotes} graphs={graphs} signalStat={signalStat} notes={notes} exportExcel={exportExcel} saveReportConfig={() => setSavingAlternateConfirmation(!savingAlternateConfirmation)} zoomEnabled={zoomEnabled} setZoomEnabled={setZoomEnabled} dragMode={dragMode} setDragMode={setDragMode} config={config} />
            }



            <div className="wrp-charttable" id="chart-table">
                <div className="container-fluid">
                    <div className="row">
                        {/* {groupProfile.length} */}
                        {
                            groupProfile.length > 0 && sessionDate && groupProfile.map(function (v, j) {

                                return (

                                    graphs.length > 0 && graphs.map(function (d, i) {
                                        let col = d.col;
                                        let row = d.row;
                                        if (col == "dynamic") {
                                            if (groupProfile.length > 4) {
                                                col = "1/3";
                                            }
                                            else {
                                                col = "1/2";
                                            }
                                        }
                                        if (row == "dynamic") {
                                            if (groupProfile.length > 4) {
                                                row = "1/3";
                                            }
                                            else {
                                                row = "1/2";
                                            }
                                        }
                                        return (
                                            <>
                                                {/* {d.signal_name.replace("signal_" , "")} */}
                                                <div className="chart-w" style={{ width: (col != "1/1" ? (eval((col)) * 99) + "%" : (eval(col) * 100) + "%"), maxWidth: (eval(col) * 100) + "%", height: "auto", minHeight: (eval(row) * 84) + "vh" }}>
                                                    {
                                                        showsci == 1 ?
                                                          <ChartSci index={j} profile={v} changesMade={changesMade} setChangesMade={setChangesMade} dragMode={dragMode} zoomEnabled={zoomEnabled}  linkGraphs={linkGraphs} linkingType={false} globalConfig={globalConfig} setGlobalConfig={setGlobalConfig}  sessionDate={sessionDate} multi={false} group={true} showActualTime={showActualTime} showSignalStat={showSignalStat} setStats={setStats} col={col} row={row}  setConfig={setConfig} record={record}  session={session} signalO={d.signal_name} signal={d.signal_name.replace("signal_petco2_avg", "petco2")} xmax={d.xmax} xmin={d.xmin} ymin={d.ymin} ymax={d.ymax} thick={d.thick} otherConfig={d.other_config} graph_order={d.graph_order} type={d.type} color={d.color} />
                                                        
                                                                                                        :
                                                    <Chart changesMade={changesMade} setChangesMade={setChangesMade} dragMode={dragMode} zoomEnabled={zoomEnabled} sessionDate={sessionDate} linkGraphs={linkGraphs} globalConfig={globalConfig} setGlobalConfig={setGlobalConfig} group={true} profile={v} index={j} showActualTime={showActualTime} showSignalStat={showSignalStat} setStats={setStats} col={col} row={row} setConfig={setConfig} record={record} session={session} signalO={d.signal_name} signal={d.signal_name.replace("signal_petco2_avg", "petco2")} xmax={d.xmax} xmin={d.xmin} ymin={d.ymin} ymax={d.ymax} thick={d.thick} otherConfig={d.other_config} graph_order={d.graph_order} type={d.type} color={d.color} />
                                    }
                                                </div>
                                            </>


                                        )

                                    })

                                )
                            })
                        }



                    </div>
                </div>
            </div>



            {/* request processing modal */}

            <Modal isOpen={requestProcessingModal} toggle={requestProcessingModalToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={requestProcessingModalToggle}><span className="ml-1 roititle modal-head">Request processing...</span></ModalHeader>
                <ModalBody>
                    <p className='text-center'>Please wait...</p>
                    <div className="wrp-chart-loader">
                        <div class="loading">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                        </div>
                    </div>
                </ModalBody>

            </Modal>

            {/* request processing modal  */}


            {/* request processed modal */}

            <Modal isOpen={requestProcessedModal} toggle={requestProcessedModalToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={requestProcessedModalToggle}><span className="ml-1 roititle modal-head">Request processed.</span></ModalHeader>
                <ModalBody>
                    <p className='text-center'>Your request has been processed succesfully.</p>

                </ModalBody>

            </Modal>

            {/* request processed modal  */}



            {/* alternate report confirmation  modal */}

            <Modal isOpen={savingAlternateConfirmation} toggle={savingAlternateConfirmationToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={savingAlternateConfirmationToggle}><span className="ml-1 roititle modal-head">Confirm request.</span></ModalHeader>
                <ModalBody>
                    <p className='text-center'>Are you sure you want to save the current configuration as an alternate configuration of this report?</p>
                    <div className='d-flex justify-content-around mt-3'>
                        <button className='lightbtn w-100' onClick={savingAlternateConfirmationToggle} >Cancel</button>
                        <button className='darktbtn w-100 ml-1' onClick={saveReportConfig} >Save</button>
                    </div>
                </ModalBody>

            </Modal>

            {/* alternate report confirmation  modal  */}





            {/* savng report confirmation modal */}

            <Modal isOpen={savingReportConfirmation} toggle={savingReportConfirmationToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={savingReportConfirmationToggle}><span className="ml-1 roititle modal-head">Confirm request.</span></ModalHeader>
                <ModalBody>
                    <p className=''>Please enter the name of report you want to save.</p>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">{sessionDate}</span>
                        </div>
                        <input type="text" class="form-control" value={reportName} onChange={Handlereportsavegroupinput} placeholder="Report Name" aria-label="Report Name" aria-describedby="basic-addon1" />

                    </div>

                    {
                        reporterror && <p className='requirdfield'>Required Field</p>
                    }

                    <div className='d-flex justify-content-around mt-3'>
                        <button className='lightbtn w-100' onClick={savingReportConfirmationToggle} >Cancel</button>
                        <button className='darktbtn w-100 ml-1' onClick={saveReport} >Save</button>
                    </div>
                </ModalBody>

            </Modal>

            {/* saving report confirmation  modal  */}

        </div>
    )
}

export default GroupChartTable;