import { useEffect, useRef, useState } from "react";
import { ChartModifierBase2D, CursorModifier, DateLabelProvider, DateTimeNumericAxis, EAxisType, EChart2DModifierType, EExecuteOn, ESeriesType, EThemeProviderType, FastColumnRenderableSeries, FastImpulseRenderableSeries, FastLineRenderableSeries, HorizontalLineAnnotation, MouseWheelZoomModifier, NumberRange, NumericAxis, RolloverModifier, RubberBandXyZoomModifier, SciChartJsNavyTheme, SciChartSurface, SplineMountainRenderableSeries, XyDataSeries, ZoomExtentsModifier, ZoomPanModifier } from "scichart";
import { SciChartReact } from "scichart-react";
import { CustomDateTimeLabelProvider } from "./CustomDateTimeLabelProvider ";
import { Mouse } from "@material-ui/icons";
import { ClickModifier } from "./ClickModifier";
import { exec } from "apexcharts";
import { CustomZoomWheelModifier } from "./ZoomWheelModifier";
import { CustomZoomDragModifier } from "./ZoomDragModifier";
import { CustomRubberBandXyZoomModifier } from "./CustomRubberBandXyZoomModifier";

SciChartSurface.loadWasmFromCDN();
SciChartSurface.setRuntimeLicenseKey(process.env.REACT_APP_SCI_KEY)
SciChartSurface.UseCommunityLicense()
const SciChart = ({ yposition, x, y, threshHoldValue, xAxisMax, xAxisMin, color, signalName, type, thick, grid, yAxisMax, yAxisMin, thresholdtLine, thresholdtcolor, thresholdthick, showThresholdLine, signalLinetype, textAnnotations, showAnnotation, hoverMode, invertY, xAxisA, yAxisA, rawSignalsAConfig, altSignal, signalLabel, setCommentModal, setCurrentPoint, setXaxisMax, setXaxisMin, play, hideThreshold, xAxisChanged, setXAxisChanged, reportComment, commentId, postupdateComment, commentUpdated, postdeleteComment, commentDeleted, index , setGlobalConfig,globalConfig,linkGraphs,group,linkingType,session,setGlobalconfigchanges,globalconfigchanges}) => {

  const sciChartSurfaceRef = useRef(null);
  const dataSeriesRef = useRef(null);

  const [wasmContext, setWasmContext] = useState(null);




  useEffect(() => {
    const initSciChart = async () => {
      //   SciChartSurface.setRuntimeLicenseKey("YOUR_LICENSE_KEY");
      console.log("threshHoldValue", thresholdtLine, thresholdtcolor, thresholdthick)
      // ✅ Create SciChartSurface
      const { sciChartSurface, wasmContext } = await SciChartSurface.create(`${signalName}_${index}_scichart-root`);
      setWasmContext(wasmContext)
      // ✅ Apply theme and manually set background
      sciChartSurface.applyTheme(new SciChartJsNavyTheme());
      sciChartSurface.background = "white"; // 🎨 Change background color

      // ✅ Add X & Y Axes

      sciChartSurface.xAxes.add(new NumericAxis(wasmContext, {
        labelProvider: new CustomDateTimeLabelProvider(), // 🕒 Format X-Axis as HH:mm:ss
        drawMajorGridLines: true, // 🚫 Hide major grid lines
        drawMinorGridLines: false, // 🚫 Hide minor grid lines
        drawBands: false, // 🚫 Hide background bands
        visibleRangeLimit: new NumberRange(x[0], x[x.length - 1]), // 📏 Limit the visible range of the X-Axis
        // visibleRange:  new NumberRange(x[0], x[x.length - 1]),
        id: "xaxis1",
        majorGridLineStyle: { color: "rgba(0,0,0,0.1)", strokeThickness: 1 }, // 🎨 Customize minor grid lines
      }));



      sciChartSurface.yAxes.add(new NumericAxis(wasmContext, {
        axisAlignment: yposition == "1" ? "Left" : "Right", // 🎯 Align Y axis to left
        drawMajorGridLines: true, // 🚫 Hide major grid lines
        drawMinorGridLines: false, // 🚫 Hide minor grid lines
        drawBands: false, // 🚫 Hide background bands
        id: "yaxis1",
        flippedCoordinates: invertY == 1, // 🔄 Flip the Y-Axis to the right
        majorGridLineStyle: { color: "rgba(0,0,0,0.1)", strokeThickness: 1 }, // 🎨 Customize minor grid lines
      }));

      // sciChartSurface.yAxes.add(new NumericAxis(wasmContext, {
      //   axisAlignment: yposition == "1" ? "Right" : "Left", // 🎯 Align Y axis to left
      //   drawMajorGridLines: true, // 🚫 Hide major grid lines
      //   drawMinorGridLines: false, // 🚫 Hide minor grid lines
      //   drawBands: false, // 🚫 Hide background bands
      //   isVisible: altSignal ? true : false,
      //   id: "yaxis2",
      //   flippedCoordinates  : invertY == 1 , // 🔄 Flip the Y-Axis to the right
      //   majorGridLineStyle: { color: "rgba(0,0,0,0.1)", strokeThickness: 1 }, // 🎨 Customize minor grid lines
      // }));



      const dataSeries = new XyDataSeries(wasmContext, {
        xValues: x,
        yValues: y
      });

      const alternateSeries = new XyDataSeries(wasmContext, {
        xValues: [],
        yValues: []
      });



      // const threshHolddataSeries = new XyDataSeries(wasmContext, {
      //    xValues: x,
      //    yValues: threshHoldValue
      //  });

      // sciChartSurface.renderableSeries.add(
      //   new FastLineRenderableSeries(wasmContext, {
      //     dataSeries: threshHolddataSeries,

      //     isVisible: showThresholdLine ? true : false,
      //       fill: thresholdtcolor.hex ? thresholdtcolor.hex : thresholdtcolor,
      //       stroke: thresholdtcolor.hex ? thresholdtcolor.hex :  thresholdtcolor,
      //       strokeDashArray: thresholdtLine == "solid" ? [] : thresholdtLine == "dot" ? [1,1] : [2,1],
      //       strokeThickness: thresholdthick,

      //       id: "threshHold",
      //        xAxisId: "xaxis1",
      //           yAxisId: "yaxis1"
      // }))

      // ✅ Add Line Series to Chart
      // if(type == "line"){


      sciChartSurface.renderableSeries.add(
        new FastLineRenderableSeries(wasmContext, {
          dataSeries,
          tooltipTitle: signalName,
          fill: color.hex ? color.hex : color,
          stroke: color.hex ? color.hex : color,
          strokeDashArray: signalLinetype == "solid" ? [] : thresholdtLine == "dot" ? [1, 1] : [5, 1],
          strokeThickness: thick,
          isVisible: type == "line" ? true : false,
          id: "signal",
          xAxisId: "xaxis1",
          yAxisId: "yaxis1"

        }))



      // }else{

      sciChartSurface.renderableSeries.add(
        new FastColumnRenderableSeries(wasmContext, {
          dataSeries,

          fill: color.hex ? color.hex : color,
          stroke: color.hex ? color.hex : color,
          strokeDashArray: signalLinetype == "solid" ? [] : thresholdtLine == "dot" ? [1, 1] : [5, 1],
          isVisible: type == "line" ? false : true,
          strokeThickness: thick,
          id: "signalbar",
          xAxisId: "xaxis1",
          yAxisId: "yaxis1"

        })
      )

      console.log("rawSignalsAConfig", rawSignalsAConfig)
      if (xAxisA.length > 0 && yAxisA.length > 0 && yAxisA.length == xAxisA.length) {
        // if(rawSignalsAConfig.type == "line"){
        sciChartSurface.renderableSeries.add(
          new FastLineRenderableSeries(wasmContext, {
            dataSeries: alternateSeries,
            stroke: rawSignalsAConfig.color.hex ? rawSignalsAConfig.color.hex : rawSignalsAConfig.color,
            strokeDashArray: [],
            isVisible: altSignal ? true : false,

            strokeThickness: thick,
            id: "asignalbar",
            xAxisId: "xaxis1",
            yAxisId: "yaxis2"

          })
        )
        // }
        // else{
        //   sciChartSurface.renderableSeries.add(
        //     new FastColumnRenderableSeries(wasmContext, {
        //         dataSeries: alternateSeries,
        //         fill: rawSignalsAConfig.color.hex ? rawSignalsAConfig.color.hex : color,
        //         stroke: rawSignalsAConfig.color.hex ? rawSignalsAConfig.color.hex :  color,
        //         strokeDashArray:  [] ,
        //         isVisible: altSignal ? true : false,
        //         strokeThickness: thick,
        //         id: "asignalbar",
        //         xAxisId: "xaxis1",
        //         yAxisId: "yaxis1"

        //     })    
        // )
        // }
      }



      // }
      console.log("xaxisrange", xAxisMin, xAxisMax)
      if (xAxisMin == "0") {
        setXaxisMin(x[0])
      }
      if (xAxisMax == "full") {
        setXaxisMax(x[x.length - 1])
      }
      sciChartSurface.xAxes.get(0).visibleRange = new NumberRange(xAxisMin == "0" ? x[0] : xAxisMin, xAxisMax == "full" ? x[x.length - 1] : xAxisMax);
      sciChartSurface.yAxes.get(0).visibleRange = new NumberRange(yAxisMin, yAxisMax);
      sciChartSurface.xAxes.get(0).drawMajorGridLines = grid == 1 || grid == 4 ? true : false;
      sciChartSurface.yAxes.get(0).drawMajorGridLines = grid == 1 || grid == 3 ? true : false;
      console.log("textAnnotations", textAnnotations, hideThreshold)
      textAnnotations.push(new HorizontalLineAnnotation({
        id: 'threshhold',
        yAxisId: "yaxis1",
        y1: threshHoldValue,
        isHidden: !hideThreshold,
        strokeThickness: thresholdthick,
        stroke: thresholdtcolor.hex ? thresholdtcolor.hex : thresholdtcolor,
        strokeDashArray: thresholdtLine == "solid" ? [] : thresholdtLine == "dot" ? [1, 1] : [5, 1],
      }))
      sciChartSurface.annotations.add(...textAnnotations);
      // sciChartSurface.annotations.add()
      sciChartSurface.chartModifiers.add(
        new CustomZoomDragModifier((args) => {
          console.log("Mouse wheel used!", sciChartSurface.xAxes.get(0).visibleRange);
          setXaxisMax(Number(sciChartSurface.xAxes.get(0).visibleRange.max));
          setXaxisMin(Number(sciChartSurface.xAxes.get(0).visibleRange.min));
            setGlobalConfig(prevState => ({
            ...prevState,
            xmax: Number(sciChartSurface.xAxes.get(0).visibleRange.max),
            xmin: Number(sciChartSurface.xAxes.get(0).visibleRange.min)
      
        }))
      },{ xyDirection: "XDirection" }),
        // new ZoomExtentsModifier(),
        new CustomZoomWheelModifier((args) => {
          console.log("Mouse wheel used!", sciChartSurface.xAxes.get(0).visibleRange);
          setXaxisMax(Number(sciChartSurface.xAxes.get(0).visibleRange.max));
          setXaxisMin(Number(sciChartSurface.xAxes.get(0).visibleRange.min));
            setGlobalConfig(prevState => ({
            ...prevState,
            xmax: Number(sciChartSurface.xAxes.get(0).visibleRange.max),
            xmin: Number(sciChartSurface.xAxes.get(0).visibleRange.min)
      
        }))
      }, { xyDirection: "XDirection"}),
      new CustomRubberBandXyZoomModifier((start) => {
        console.log("Mouse wheel used! rubber", sciChartSurface.xAxes.get(0).visibleRange);
        setXaxisMax(Number(sciChartSurface.xAxes.get(0).visibleRange.max));
        setXaxisMin(Number(sciChartSurface.xAxes.get(0).visibleRange.min));
          setGlobalConfig(prevState => ({
          ...prevState,
          xmax: Number(sciChartSurface.xAxes.get(0).visibleRange.max),
          xmin: Number(sciChartSurface.xAxes.get(0).visibleRange.min)
    
      }))
    },{ xyDirection: "XDirection", executeOn: EExecuteOn.MouseRightButton }),
        // new RubberBandXyZoomModifier({ xyDirection: "XDirection", executeOn: EExecuteOn.MouseRightButton }),


        new ClickModifier({ id: "clicked", isEnabled: hoverMode, cb: handleClick }),
      );


      // { type: EChart2DModifierType.ZoomPan, options: { enableZoom: true } },
      //             { type: EChart2DModifierType.MouseWheelZoom },
      //             { type: EChart2DModifierType.ZoomExtents }

      dataSeriesRef.current = dataSeries;

      sciChartSurfaceRef.current = sciChartSurface;
    };

    if (signalName) {
      initSciChart();

    }


    return () => {
      sciChartSurfaceRef.current?.delete();
    };
  }, [signalName]);

  const handleClick = (args) => {
    passdata(args)
  }

  const passdata = (args) => {
    console.log("args", args, hoverMode)

    setCommentModal(true);
    setCurrentPoint(args)

  }
 


  const getTooltipDataTemplate = (seriesInfo, tooltipTitle, tooltipLabelX, tooltipLabelY) => {
    console.log("seriesInfo", seriesInfo, tooltipTitle, tooltipLabelX, tooltipLabelY)

    if (seriesInfo === undefined) return;
    const daylight = new Date(0).getTimezoneOffset() * 60 * 1000
    const sereis = seriesInfo.length > 1 ? seriesInfo[1] : seriesInfo[0];
    const date = new Date(seriesInfo.xValue);
    const x_value = date.toTimeString().split(' ')[0];
    const lines = [];

    lines.push(`Time: ${x_value}`)
    lines.push(`${signalLabel} : ${parseFloat(seriesInfo.yValue).toFixed(2)}`)

    // lines.push(`);
    return lines;
  };

  // useEffect(() => {
  //   if (sciChartSurfaceRef.current) {
  //     const sciChartSurface = sciChartSurfaceRef.current;
  //     sciChartSurface.xAxes.get(0).visibleRangeChanged.subscribe((args) => {
       
  //       if (!play) {
       
          
        
          

  //         setXaxisMax(args.visibleRange.max);
  //         setXaxisMin(args.visibleRange.min);
  //         // setXAxisChanged(xAxisChanged + 1)
  //         console.log("continous independent change")
      

  //       // setGlobalconfigchanges(true);
  //       // }
  //     }
     

  //     });
  //   }
  // }, [sciChartSurfaceRef.current])

   

  useEffect(() => {
    if (sciChartSurfaceRef.current) {

      const sciChartSurface = sciChartSurfaceRef.current;
      //   sciChartSurface.xAxes.get(0).visibleRangeChanged.subscribe((args) => {
      //     console.log("X Axis zoom changed", args.visibleRange);
      //     setXaxisMax(parseInt(args.visibleRange.max));
      //     setXaxisMin(parseInt(args.visibleRange.min));
      // });
      console.log("range", xAxisMax, xAxisMin)
      // const threshHolddataSeries = new XyDataSeries(wasmContext, {
      //   xValues: x,
      //   yValues: threshHoldValue
      // });

      const signalSeries = new XyDataSeries(wasmContext, {
        xValues: x,
        yValues: y
      });
      // handleClick({})

      if (xAxisA.length > 0 && yAxisA.length > 0 && yAxisA.length == xAxisA.length) {
        console.log("alternateSeries", xAxisA, yAxisA)
        const alternateSeries = new XyDataSeries(wasmContext, {
          xValues: xAxisA,
          yValues: yAxisA
        });
        console.log("rawSignalsAConfig", altSignal, rawSignalsAConfig)

        if (sciChartSurface.renderableSeries.get(2)) {

          sciChartSurface.renderableSeries.get(2).dataSeries = alternateSeries;
          sciChartSurface.renderableSeries.get(2).isVisible = altSignal ? true : false;

        }
        else {
          // if(rawSignalsAConfig.type == "line"){
          sciChartSurface.renderableSeries.add(
            new FastLineRenderableSeries(wasmContext, {
              dataSeries: alternateSeries,
              stroke: rawSignalsAConfig.color.hex ? rawSignalsAConfig.color.hex : rawSignalsAConfig.color,
              strokeDashArray: [],
              isVisible: altSignal ? true : false,
              strokeThickness: thick,
              id: "asignalbar",
              xAxisId: "xaxis1",
              yAxisId: "yaxis1"

            })
          )
          // }
          // else{
          //   sciChartSurface.renderableSeries.add(
          //     new FastColumnRenderableSeries(wasmContext, {
          //       dataSeries: alternateSeries,             
          //         fill: rawSignalsAConfig.color.hex ? rawSignalsAConfig.color.hex : rawSignalsAConfig.color,
          //         stroke: rawSignalsAConfig.color.hex ? rawSignalsAConfig.color.hex :  rawSignalsAConfig.color,
          //         strokeDashArray:  [] ,
          //         isVisible: altSignal ? true : false,
          //         strokeThickness: thick,
          //         id: "asignalbar",
          //         xAxisId: "xaxis1",
          //         yAxisId: "yaxis1"

          //     })    
          // )
          // }
        }
      }


      // sciChartSurface.renderableSeries.get(0).fill = type == "obar" ? "#fff" : thresholdtcolor.hex ? thresholdtcolor.hex : thresholdtcolor;
      // sciChartSurface.renderableSeries.get(0).stroke = thresholdtcolor.hex ? thresholdtcolor.hex : thresholdtcolor;
      // sciChartSurface.renderableSeries.get(0).strokeThickness = thresholdthick;
      // sciChartSurface.renderableSeries.get(0).isVisible = showThresholdLine ? true : false,

      // sciChartSurface.renderableSeries.get(0).strokeDashArray = thresholdtLine == "solid" ? [] : thresholdtLine == "dot" ? [1,1] : [5,1]

      // sciChartSurface.renderableSeries.get(0).dataSeries = threshHolddataSeries;


      sciChartSurface.renderableSeries.get(0).stroke = color.hex ? color.hex : color;
      sciChartSurface.renderableSeries.get(0).strokeThickness = thick;
      sciChartSurface.renderableSeries.get(0).strokeDashArray = signalLinetype == "solid" ? [] : signalLinetype == "dot" ? [1, 1] : [5, 1];
      sciChartSurface.renderableSeries.get(0).isVisible = type == "line" ? true : false,
        sciChartSurface.renderableSeries.get(0).dataSeries = signalSeries;


      sciChartSurface.renderableSeries.get(1).fill = type == "obar" ? "#fff" : color.hex ? color.hex : color;
      sciChartSurface.renderableSeries.get(1).stroke = color.hex ? color.hex : color;
      sciChartSurface.renderableSeries.get(1).strokeDashArray = thick;
      sciChartSurface.renderableSeries.get(1).strokeDashArray = signalLinetype == "solid" ? [] : signalLinetype == "dot" ? [1, 1] : [5, 1];
      sciChartSurface.renderableSeries.get(1).isVisible = type == "line" ? false : true,
        sciChartSurface.renderableSeries.get(1).dataSeries = signalSeries;
      console.log("xaxisrange",sciChartSurface.xAxes.get(0).visibleRange , xAxisMin, xAxisMax)

      if(!globalconfigchanges){
        sciChartSurface.xAxes.get(0).visibleRange = new NumberRange(xAxisMin == "0" ? x[0] : xAxisMin, xAxisMax == "full" ? x[x.length - 1] : xAxisMax);  
      }
      
      
      // sciChartSurface.xAxes.get(0).visibleRange = new NumberRange(xAxisMin == "0" ? x[0] : xAxisMin, xAxisMax == "full" ? x[x.length - 1] : xAxisMax);
      sciChartSurface.yAxes.get(0).visibleRange = new NumberRange(yAxisMin, yAxisMax);
      // sciChartSurface.yAxes.get(1).visibleRange = new NumberRange(yAxisMin, yAxisMax); 
      // sciChartSurface.yAxes.get(1).isVisible = altSignal ? true: false; 
      console.log("yposition", yposition)
      sciChartSurface.yAxes.get(0).axisAlignment = yposition == "1" ? "Left" : "Right";
      // sciChartSurface.yAxes.get(1).axisAlignment = yposition == "1" ? "Right" : "Left" ; 

      sciChartSurface.xAxes.get(0).drawMajorGridLines = grid == 1 || grid == 4 ? true : false;
      sciChartSurface.yAxes.get(0).drawMajorGridLines = grid == 1 || grid == 3 ? true : false;
      sciChartSurface.yAxes.get(0).flippedCoordinates = invertY == 1 ? true : false;
      // sciChartSurface.yAxes.get(1).flippedCoordinates = invertY == 1  ? true : false;
      // sciChartSurface.renderableSeries.get(0).strokeThickness = thick;
      // sciChartSurface.annotations = textAnnotations;
      console.log("showAnnotation", signalLabel, sciChartSurface.annotations)
      // sciChartSurface.annotations.add(...textAnnotations);

      if (showAnnotation == 1) {
        if (textAnnotations.length != sciChartSurface.annotations.items.length && !commentDeleted) {
          // sciChartSurface.annotations.clear();
          sciChartSurface.annotations.add(textAnnotations[textAnnotations.length - 1]);
        } else {
          if (commentUpdated) {
            sciChartSurface.annotations.items.forEach((annotation) => {
              if (annotation.id == commentId) {
                annotation.text = reportComment;
                postupdateComment()
              }
            })

          }
          if (commentDeleted) {
            sciChartSurface.annotations.items.forEach((annotation) => {
              if (annotation.id == commentId) {
                annotation.text = "";
                annotation.isHidden = true;
                postdeleteComment()
              }
            })

          }

        }
        // sciChartSurface.annotations.add(textAnnotations)
      }


      sciChartSurface.annotations.items.forEach((annotation) => {
        if (annotation.id == "threshhold") {
          annotation.isHidden = !hideThreshold;
          annotation.strokeThickness = thresholdthick;
          annotation.stroke = thresholdtcolor.hex ? thresholdtcolor.hex : thresholdtcolor;
          annotation.y1 = threshHoldValue;
          annotation.strokeDashArray = thresholdtLine == "solid" ? [] : thresholdtLine == "dot" ? [1, 1] : [5, 1];
        }
      })
      // if(showAnnotation == 2){
      //   sciChartSurface.annotations.clear();
      // }

      sciChartSurface.chartModifiers.getById("clicked").isEnabled = hoverMode;
      // sciChartSurface.chartModifiers.items[3].showTooltip = hoverMode;
      // sciChartSurface.chartModifiers.items[3].showXLine = hoverMode;
      const modifier = sciChartSurface.chartModifiers.getById("hover")
      console.log("hoverMode", modifier)
      if (hoverMode) {
        sciChartSurface.chartModifiers.add(new RolloverModifier({
          id: "hover",
          // tooltipContainerBackground: "purple",
          // showXLine: hoverMode,
          showAxisLabel: false,
          rolloverLineStrokeDashArray: [5, 2],
          showTooltip: true,
          rolloverLineStroke: color.hex ? color.hex : color,
          showRolloverLine: true,
          isEnabled: true,


          // showYLine : false, 
          // showTooltip : hoverMode,  
          // hitTestRadius: 2,
          // tooltipLegendTemplate: getTooltipLegendemplate,
          tooltipDataTemplate: getTooltipDataTemplate,
        }))
      }
      else {
        if (modifier !== undefined) {
          sciChartSurface.chartModifiers.remove(modifier)
        }
      }
    }

  }, [yposition, color, xAxisChanged, thick, type, x, y, grid, yAxisMin, yAxisMax, threshHoldValue, thresholdtLine, thresholdtcolor, thresholdthick, showThresholdLine, signalLinetype, textAnnotations, showAnnotation, invertY, xAxisA, yAxisA, altSignal, hoverMode, play, hideThreshold, commentUpdated, commentDeleted])

  return (
    //         <SciChartReact
    //         ref={chartRef}
    //         style={{ width: "100%", height: "100%" , backgroundColor: "white"}}
    //         config={{
    //          surface: { theme: {
    //             sciChartBackground: "white",
    //          }},

    //         xAxes: [{ id: "primary_xaxis", type: EAxisType.NumericAxis , sciChartBackground: "white" , visibleRange: new NumberRange(xAxisMin, xAxisMax) }],
    //         yAxes: [{ id: "primary_yaxis",placemaent:  type: EAxisType.NumericAxis , sciChartBackground: "white" }],
    //         background: "white", // 🎨 Change background color here
    //         theme: new SciChartJsNavyTheme(), // Optional: Set a theme          
    //         gridBackgroundBrush: "white",
    //         plotAreaBackgroundBrush: "white",
    //         series: [
    //             {
    //                 type: ESeriesType.SplineMountainSeries,

    //                 options: {
    //                     fill: "#fff",
    //                     stroke: "#eb911c",

    //                     strokeThickness: 4,
    //                     opacity: 0.4
    //                 },                
    //                 xyData: { xValues: x, yValues: y }, 
    //                 xAxisId : "primary_xaxis", 
    //                 yAxisId : "primary_yaxis"
    //             }, 

    //             // {
    //             //     type: ESeriesType.SplineMountainSeries,
    //             //     options: {
    //             //         fill: "#3ca832",
    //             //         stroke: "#eb911c",
    //             //         strokeThickness: 4,
    //             //         opacity: 0.4
    //             //     },
    //             //     xyData: { xValues: x1, yValues: y1 }, 
    //             //     xAxisId : "primary_xaxis", 
    //             //     yAxisId : "secondary_yaxis"
    //             // }, 


    //         ],

    //         modifiers: [

    //             { type: EChart2DModifierType.ZoomPan, options: { enableZoom: true } },
    //             { type: EChart2DModifierType.MouseWheelZoom },
    //             { type: EChart2DModifierType.ZoomExtents }
    //         ]
    //     }}
    // />
    <>


      <div id={`${signalName}_${index}_scichart-root`} style={{ width: "100%", height: "100%" }} />
    </>

  )

}


export default SciChart;

